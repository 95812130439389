<template lang="pug">
  .root
    component-header-index
    div(class="container-flex")
      div(class="content-left")
        div(class="ng-header") >>
          span(class="display-title") NGワード設定

        div(v-for="(group, index1) in groupAll" class="ng-word-content")
          div(v-for="(item, index2) in group" class="ng-word-content-item")
            input(v-model="groupAll[index1][index2]" class="input-g-border")

        div(@click="onAdd()" class="link-color") >>さらに追加する

        div(class="ng-word-button")
          img(@click="onUpdate()" class="img-button" src="/img/koushin_p.png")

      div(class="content-right")
        pr-banner

    component-footer-index
</template>

<style src="./Index.scss" lang="scss" scoped>
</style>

<script>
import { getNgWordIgnoreCommonList, updateNgWordList } from '@/api/setting'
import { uniq, chunk, fill, flatten, isString } from 'lodash'

export default {
  name: "SettingNGWordIndex",
  components: { },
  data() {
    return {
      ui: {
        ngwordInput: ''
      },

      groupAll: [],
    }
  },
  props: {},
  computed: {},
  watch: {},
  mounted() {},
  async created() {
    await this.init();
  },
  updated() {},
  destroyed() {},
  methods: {
    async init() {
      let data = {
        word_list: fill(Array(20), undefined)
      }
      try {
        const ret = await getNgWordIgnoreCommonList(this.$store.state.user.id);
        if (ret.data.word_list.length > 0) {
          data = ret.data;
        }
      } catch(e) {
        console.log(e);
      }
      this.groupAll = chunk(data.word_list, 20).map(v => {
        while (v.length < 20) {
          v.push(undefined);
        }
        return v;
      });
    },
    async onAdd() {
      this.groupAll.push(fill(Array(20), undefined));
    },
    async onUpdate() {
      await updateNgWordList(uniq(flatten(this.groupAll)).filter(v => isString(v) && v.length > 0));
      this.$message({
          showClose: true,
          message: '更新が完了しました',
          type: 'success'
        });
    },
  }
};
</script>
